<template>
  <div style="">
    <!-- <div> -->
    <!-- <video-background
      src="/static/woki/video-banner.mp4"
      :poster="'/static/woki/video-banner.mp4'"
      :autoplay="false"
      :sources="[]"
      :muted="false"
      ref="videobackground"
      @click="handleVideo()"
      style="max-height: 1080px; height: 70vh"
      overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
    >
    </video-background> -->
    <v-img class="w-100 d-none d-sm-block" src="/static/woki/banner.webp" contain></v-img>

    <!-- CAROUSEL AND INTRODUCTORY TEXT -->
    <head-section></head-section>

    <div class="spacer-dots"></div>

    <!-- SERVICES -->
    <section class="container">
      <h3 class="text-uppercase text-primary text-center mb-5 w-100"
        >Nuestros sevicios de franquicias
      </h3>
      <v-row class="services justify-content-center">
        <ul class="mx-3 mb-0 px-2 py-0 col-10 col-sm-5 col-lg-4">
          <li class="h5">Finanzas para franquicias</li>
          <li class="h5">Aspectos legales para franquicias</li>
          <li class="h5">Expansión de sistemas de franquicias</li>
          <li class="h5">Diagnóstico y Auditoría para franquicias</li>
        </ul>
        <ul class="mx-3 px-2 py-0 col-10 col-sm-5 col-lg-4">
          <li class="h5">Mercadeo para franquicias</li>
          <li class="h5">Diseño de sistemas de franquicias</li>
          <li class="h5">Expansión internacional de franquicias</li>
        </ul>
      </v-row>
      <v-row class="mt-5 justify-content-center">
        <h4 class="text-uppercase text-primary text-center mb-4 mx-3 mx-md-auto w-100"
          >servicios complementarios
        </h4>
        <div class="mx-5">
          <v-row>
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Innovación y transformación digital
            </a>
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Desarrollo organizacional y Coaching</a
            >
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Sostenibilidad y Economía verde</a
            >
          </v-row>
          <v-row>
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Startups</a
            >
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Estrategia y Liderazgo</a
            >
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Finanzas</a
            >
            <a
              href="https://wokiconsulting.com/servicios"
              target="_blank"
              rel="noopener"
              class="h6 col serv-card"
              >Marketing
            </a>
          </v-row>
        </div>
      </v-row>
    </section>

    <!-- WORK BANNER -->
    <section class="work-banner my-5">
      <h3
        class="text-uppercase text-center fw-light pt-5 w-100"
        style="font-weight: 300 !important"
        >¡TÚ ELIGES COMO TRABAJAR CON TU EXPERTO WOKI!
      </h3>
      <v-row no-gutters class="py-4">
        <div class="col-12 col-md-6 px-5">
          <h4 class="text-uppercase text-center my-2 w-100"
            >SESIÓN PUNTUAL
            <v-icon class="text-center text-white" large>mdi-clock-outline</v-icon>
          </h4>
          <p class="text-center px-5 px-sm-3 px-md-5"
            >Comienza con una primera sesión de una hora para organizar y clarificar las
            dudas que puedas tener y así trazar un plan de acción con el experto que
            elijas.
          </p>
        </div>
        <div class="col-12 col-md-6 px-5">
          <h4 class="text-uppercase text-center my-2 w-100"
            >PROYECTO
            <v-icon class="text-center text-white" large
              >mdi-note-text-outline</v-icon
            ></h4
          >
          <p class="text-center px-5 px-sm-3 px-md-5"
            >Comienza con una primera sesión de una hora para organizar y clarificar las
            dudas que puedas tener y así trazar un plan de acción con el experto que
            elijas.
          </p>
        </div>
      </v-row>
    </section>
    <!-- CONTACT FORM -->
    <v-row class="block-space px-3 px-md-5 align-items-center justify-content-center">
      <div class="col-12 col-sm-11 col-lg-6 section-hero section-shaped">
        <div class="shape shape-style-2">
          <span class="span-100"></span>
          <span class="span-50"></span>
          <span class="span-75"></span>
          <!-- <span class="span-50"></span> -->

          <span class="span-50"></span>
          <span class="span-50"></span>
          <span class="span-100"></span>
          <span class="span-50"></span>
          <!-- <span class="span-100"></span> -->
          <!-- <div class="overlay-bg"></div> -->
        </div>
        <v-row no-gutters class="row-grid align-items-center">
          <div class="col-12 order-2 order-lg-1">
            <h3 class="text-uppercase text-primary w-100"
              >¿Quieres el apoyo de un experto WOKI?
            </h3>
            <p class="h5 text-primary">Déjanos tus datos y te contactaremos</p>
          </div>

          <div class="col-12 order-1 order-lg-2 d-flex align-items-center">
            <video
              controls
              width="100%"
              class="videobackground mb-5 mb-lg-0 my-lg-4 w-100"
              src="/static/woki/video-banner.mp4"
            ></video>
          </div>
        </v-row>
      </div>
      <div class="col-12 col-sm-10 col-lg-6">
        <woki-form class="shadow-lg mt-4" :company="null" :country="country"></woki-form>
      </div>
    </v-row>
    <v-img contain class="d-none d-md-block" src="/static/woki/infografía.webp" alt="" />
    <!-- <div>
    </div> -->

    <div class="single-spacer-dots"> </div>

    <!-- TO JOIN | CALL TO ACTION -->
    <section class="block-space border-top-dotted">
      <h3 class="text-uppercase text-primary text-center px-2 w-100"
        >¿ERES EXPERTO EN ALGUNAS DE NUESTRAS CATEGORÍAS EMPRESARIALES?
      </h3>
      <v-row class="cta-row py-5 my-5 justify-content-center align-items-center">
        <h4 class="col-11 col-sm-9 text-center text-white"
          >¡Únete a nuestra comunidad de expertos y consultores independientes!</h4
        >
        <v-btn
          href="https://wokiconsulting.com/registrate"
          target="_blank"
          ref="noopener"
          large
          class="col-6 col-sm-3 col-md-2 p-4 text-white"
          color="#06366f"
        >
          <p class="h5 m-0"> Regístrate </p>
        </v-btn>
      </v-row>
    </section>
    <!-- </div> -->
  </div>
</template>

<script>
  import HeadSection from '@/components/globalFrontendComponents/WokiHead'
  import WokiForm from '@/components/globalFrontendComponents/WokiForm'

  export default {
    props: { country: { type: Object } },
    name: 'Woki',
    components: { HeadSection, WokiForm },
    created() {},
    data() {
      return {
        mixer: null,
      }
    },
  }
</script>

<style lang="scss" scoped>
  $primary-color: #06366f;
  $secondary-color: #009fe2;
  $secondary-color-alpha: #009ee280;
  $cta-color: #00c2cb;
  $opacity-text: #f6f4f4c4;
  $block-space: 30px;

  .block-space {
    padding: $block-space 0;
  }
  .spacer-dots {
    margin: 50px auto;
    width: 90%;
    height: 65px;
    border-top: 20px dotted $secondary-color-alpha;
    border-bottom: 20px dotted $secondary-color-alpha;
  }
  .single-spacer-dots {
    margin: 50px auto;
    width: 90%;
    border-top: 20px dotted $secondary-color-alpha;
  }
  .videobackground {
    aspect-ratio: 16 / 9;
  }

  .serv-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    background-color: $cta-color;
    margin: 10px;
  }
  .work-banner {
    color: white;
    background-color: $primary-color;
    p {
      color: $opacity-text;
    }
  }
  .cta-row {
    background-color: $secondary-color;
  }
</style>
