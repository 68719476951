import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-section row row-grid no-gutters my-5 mx-3 mx-sm-4 mx-lg-5 p-lg-5 align-items-center section section-hero section-shaped"},[_vm._m(0),_vm._m(1),_c('swiper',{ref:"Swiper",staticClass:"col-12 col-md-6 d-flex align-items-center order-md-1",attrs:{"options":_vm.carouselOptions}},[_vm._l((_vm.swiperData),function(item,index){return _c('swiper-slide',{key:index,staticClass:"h-100"},[_c(VImg,{attrs:{"content-class":"carousel-content","cover":"","aspect-ratio":4 / 3,"src":item.webpImg}},[_c('div',{staticClass:"col-12 px-5",staticStyle:{"background-color":"#009fe2ab","border-radius":"20px"}},[_c(VRow,{staticStyle:{"color":"#ffbd59"},attrs:{"no-gutters":""}},[_vm._v(" #WOKITOOLS ")]),_c(VRow,{staticClass:"text-white",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.desc))])]),_c(VBtn,{ref:"noopener",refInFor:true,attrs:{"href":"https://mailchi.mp/wokiconsulting/wokitools","target":"_blank","small":"","color":"#ffbd59"}},[_vm._v(" Descargar herramientas ")])],1)])],1)}),_c('div',{staticClass:"custom-prev",attrs:{"slot":"button-prev"},slot:"button-prev"},[_c(VIcon,{attrs:{"large":"","color":"#fff"}},[_vm._v("mdi-chevron-left")])],1),_c('div',{staticClass:"custom-next",attrs:{"slot":"button-next"},slot:"button-next"},[_c(VIcon,{attrs:{"large":"","color":"#fff"}},[_vm._v("mdi-chevron-right")])],1)],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape shape-style-1"},[_c('span',{staticClass:"span-100"}),_c('span',{staticClass:"span-50"}),_c('span',{staticClass:"span-150"}),_c('span',{staticClass:"span-75"}),_c('span',{staticClass:"span-50"}),_c('span',{staticClass:"span-50"}),_c('span',{staticClass:"span-100"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content col-12 col-md-6 px-0 pr-md-0 pl-md-3 pl-lg-5 mb-4 order-md-2"},[_c('h1',{staticClass:"h2",staticStyle:{"color":"#009fe2"}},[_vm._v("¡IMPULSA TU FRANQUICIA CON WOKI!")]),_c('p',{staticClass:"h4",staticStyle:{"line-height":"2rem"}},[_vm._v("Te conectamos con "),_c('b',[_vm._v("los mejores expertos")]),_vm._v(" y consultores freelance y te damos todas las "),_c('b',[_vm._v("herramientas")]),_vm._v(" necesarias "),_c('b',[_vm._v("para que lleves tu negocio al siguiente nivel")])])])
}]

export { render, staticRenderFns }